@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600&display=swap');
body {
  font-family: 'Roboto', sans-serif;
}


:root
{
  --green: rgba(23,130,80,1);
  --red: rgba(226,79,50,1);
  --blue: rgba(0,148,255,1);
  --secondary-bg: #35353559;
}

/* new desktop design css start */
.desktop_stage {
  display: flex;
  background-color: #1d2027;
  padding-top: 0;
  color: #fff;
}
.header_stage {
  background-color: #000;
  color: #fff;
  display: flex;
  align-items: end;
  z-index: 1;
  width: 100%;
  justify-content: end;
  position: sticky;
  top: 0;
  padding: 17px 20px;
}
.menu-item {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 30%;
}
.stage1P1 {
  width: 10%;
  position: sticky;
  top: 90px;
}
.stage1P2 {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
}
.header_graph {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px;
}
.footer_graph_set {
  display: flex;
  align-items: center;
  padding: 13px;
}
.footer_graph_set span {
  border-right: 1px solid #fff;
  margin: 0 10px;
  padding: 0 20px;
}
.footer_graph_set span:last-child {
  border-right: unset;
  margin: 0;
}
.footer_graph_set span:first-child {
  padding-left: 0;
  margin-left: 0;
}
.left_header_graph {
  display: flex;
  align-items: center;
  width: 50%;
}
.right_div2 span:nth-child(2) {
  border-left: 1px solid #fff;
  padding: 0 0 0 10px;
  margin: 0 0 0 10px;
}
.right_div2 {
  display: flex;
  align-items: center;
}
.right_inside_header_graph {
  display: flex;
  flex-direction: column;
}
.graph_mid_img {
  height: 73vh;
}
.right_header_graph {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 55%;
}
.right_div3 {
  display: flex;
}
.right_div3 button:first-child {
  margin-right: 10px;
}
.right_div3 button {
  background-color: #6046cf;
  color: #fff;
  width: 100%;
  min-width: 120px;
  padding: 8px;
  border-radius: 7px;
  font-size: 13px;
}
.stage1P1 span {
  color: #fff;
}

.desktop_stage {
  display: flex;
  align-items: flex-start;
}
.footer_stage {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.custom_panding_heading {
  justify-content: space-between;
  padding-bottom: 5px;
  padding-top: 5px;
}
.custom_panding_heading .pending-buy_btnn {
  margin-left: 24px;
  color:  var(--green);
}
.desktop_stage .mid_btn {
  display: flex;
}

.desktop_stage .small-text {
  color: grey;
  font-size: 12px;
}

.negative {
  color: #b4b4b4;
}
.desktop_stage .mid_btn button {
  display: flex;
}
.desktop_stage .price-block {
  justify-content: end;
  /* margin-top: -17px; */
  margin-top: 0px;

}
.desktop_stage .executed-part,
.desktop_stage .headerbox > div {
  width: 100%;
}

/* new desktop design css end */

.fotter-img {
  height: 30px;
}
.mobile_show {
  display: none;
}
iframe {
  pointer-events: none;
}
.dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0;
}

.value {
  color: grey !important;
}

.display-time {
  color: grey !important;
  font-size: 12px;
}


a.tax-link:link {color:#ff0000;}
a.tax-link:visited {color:#0000ff;}
a.tax-link:hover {color:#ffcc00;text-decoration: underline;cursor: pointer;}

.login-form label {
  color: #fff;
  font-size: 14px;
}

.login-form input::placeholder
{
  font-family: 'Roboto', sans-serif;

}
.forget-password a {
  cursor: pointer;
}

.forget-password {
  font-size: 13px;
  margin: 15px 0px;
}
.login-form input {
  width: 100%;
  height: 38px;
  background-color: #2b2e32 !important;
  box-shadow: none !important;
  border: 1px solid #32373d;
  font-size: 14px;
  padding-left: 13px !important;
  margin-top: 3px;
}

.login-form button.btn.btn-primary.btn.btn-contained {
  width: 100%;
  padding: 9px;
}

.negative {
  /* color: #f15131 !important; */
   /* color: rgb(170, 168, 168);*/
  color:  var(--red);
}
.positive {
  /* color: #1fbf75 !important; */
 /* color: grey;*/
 color:  var(--green);
}

.profitability {
  background-color: #0094ff !important;
}
.open-order .MuiAccordion-rounded {
  background-color: #14161a !important;
  color: #fff !important;
}
.open-order .placed-order-row {
  background: #14161a;
  margin-bottom: 20px;
  border-radius: 14px;
  padding: 5px;
}
.MuiAccordionSummary-expandIconWrapper {
  color: #fff !important;
}
.all-values ul li b {
  color: #fff;
  font-size: 14px;
}
.crypto-top-row {
  display: flex;
  column-gap: 10px;
}
.overview-stats {
  background-color: #000;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 15px;
}
li.closed-order {
  margin-bottom: 15px;
}
.header-row {
  display: flex;
  align-items: end;
  grid-column-gap: 10px;
  column-gap: 10px;
  font-size: 13px;
  justify-content: space-between;
}
.all-values {
  margin-top: 10px;
}
.header-row img {
  width: 40px;
}

.all-values ul li span {
  color: #93a3ae;
  font-size: 15px;
}

.open-order h3 {
  font-size: 19px;
  margin-bottom: 15px;
}
.all-values ul li {
  color: #70808c;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.all-values ul li {
  list-style: none;
}
.all-values ul {
  padding-left: 0;
}
.align-items-center .d-block {
  margin-bottom: 0;
  font-size: 14px;
}

.d-block-list {
  margin-bottom: 0;
  font-size: 14px;
}
button.timer-btn {
  padding: 12px;
  border-radius: 8px;
  border: 0;
  background-color: #2a2b2c;
  color: #fff;
}
.overview-stats span {
  color: #fff;
  font-size: 12px;
}
.MuiAccordionSummary-content {
  display: grid !important;
}
.other-values {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}
.MuiAccordionSummary-expandIconWrapper {
  position: absolute;
  top: 10px;
  right: 8px;
}
.overview-stats {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.placed-order-row {
  padding-bottom: 20px !important;
}
span.stats-value {
  font-size: 15px;
  display: block;
}
span.op-row-pl-icon svg {
  font-size: 17px;
  vertical-align: sub;
}
.header-row .negative svg {
  transform: rotate(91deg);
}
.unit-qty::placeholder {
  color: black !important;
}
.unit-qty label {
  color: black !important;
}
button.clode-btn {
  width: 96%;
  border: 0;
  box-shadow: none;
  background-color: #404040;
  border-radius: 8px;
  padding: 8px;
  margin: 0 auto !important;
  text-align: center;
  display: block;
  color: #fff;
  font-size: 13px;
}
.MuiList-root {
  background-color: #24262e !important;
  color: white !important;
}

div#content {
  overflow: hidden;
  width: 100%;
  display: flex;
  align-items: center;
}

.align-center {
  align-items: center;
}
.trade-btn {
  display: flex;
  align-items: center;
  justify-content: inherit;
  padding: 12px 18px;
}
.trade-btn {
  border: 0;
  padding: 12px;
  width: 100%;
  border-radius: 8px;
}
.down-arow svg {
  transform: rotate(90deg) !important;
}
button.sell-btn {
  background-color: #f15131;
  color: #fff;
  font-size: 17px;
}

button.buy-btn {
  background-color: #1fbf75;
  color: #fff;
  font-size: 17px;
}

.trade-btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 20px;
}

i.closed-order {
  margin-bottom: 15px;
}

button.clode-btn.close-tab {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 14px;
}
.main-window {
  overflow: auto;
}
.footer-main .footer-btns .btn img {
  width: 20px;
  height: 20px;
  margin-bottom: 6px;
  filter: brightness(100);
}

.footer-main .footer-btns .btn.active-btn img {
  filter: none;
}
.footer-main .footer-btns .btn.active-btn span
{
  color: #0094ff;
}
.footer-main {
  background-color: #000000;
  /* padding-top: 10px !important;
  padding-bottom: 10px !important; */
}
.footer-btns .btn span {
  font-size: 10px;
  text-transform: uppercase;
  display: block;
}

.footer-main .footer-btns .btn {
  padding-bottom: 0;
}
.trade-btns {
  position: fixed;
  bottom: 58px;
  left: 0;
  right: 0;
  width: 100%;
  margin: 0 auto;
  background: #000000;
  padding: 9px 12px;
  padding-top: 13px;
}

.overview-stats {
  position: sticky;
  left: 0;
  right: 0;
  top: -3px;
  z-index: 9999999999;
}

span.d-block.username {
  color: #0094ff;
  font-size: 13px;
  display: block;
}
/* .footer-btns .active-btn {
  background-color: #000000 !important;
  color: #2cb9e4;
} */
span.counts {
  position: absolute;
  top: -7px;
  background-color: #2fc2ea;
  width: 24px;
  height: 24px;
  line-height: 20px;
  text-align: center;
  border-radius: 100%;
  font-size: 12px;
  border: 3px solid #000000;
}

.footer-btns button.btn.btn-secondary {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
  background-color: #404040;
}
.footer-main .footer-btns .btn.active-btn svg
{
  color: #0094ff;
}
.footer-btns .btn {
  width: 100%;
  padding: 2px 2px;
  position: relative;
}
.footer-btns {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 12px;
}

.quantity {
  display: flex;
}
.set-popup.show {
  bottom: 130px;
  height: auto;
  opacity: 1;
}

.set-popup {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 999999999999999999999 !important;
  background-color: black;
  padding: 14px;
  bottom: 0;
  height: 0;
  opacity: 0;
}

.set-popup {
  position: fixed;
  bottom: 130px;
  left: 0;
  right: 0;
  z-index: 999999999999999999999 !important;
  background-color: black;
  padding: 14px;
}

.quantity-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 5%;
}

.quantity-inner .quantity {
  border: 1px solid #2b2b2b;
  border-radius: 12px;
  height: 40px;
  font-size: 18px;
  display: flex;
  align-items: center;
}
.quantity-inner input,
.quantity-inner button {
  width: 100%;
  background-color: #000;
  text-align: center;
  color: #fff;
  box-shadow: none;
  border: 0;
  border-radius: 17px;
}

/* button.plus, button.minus {
        width: auto;
        padding: 0px 10px;
        font-size: 18px;
    } */

.selector {
  background-color: #14161a;
  border-radius: 8px;
  border: 1px solid #5a5a5a;
  height: 40px;
}
.selector > * {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}
.selector input {
  width: 100%;
}
.selector {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
}
.multiplier-btn {
  width: 20%;
}
.profit-loss-input {
  width: 30%;
}
.profit-loss-input input {
  text-align: center;
}
.amount-input {
  text-align: center;
  width: 30%;
}
.profit-loss-input {
  display: flex;
}
.amount-input input::placeholder,
.profit-loss-input input::placeholder {
  color: #fff;
}
.amount-input input,
.profit-loss-input input {
  text-align: center;
  background-color: #14161a;
  border: 0;
  box-shadow: none;
  color: #fff;
}
.amount-input input,
.profit-loss-input input {
  outline: none;
}
.selector {
  position: fixed;
  width: calc(100% - 23px);
  left: 0;
  right: 0;
  bottom: 130px;
  margin: 0 auto;
}

.multiplier-sec.show {
  transition: 0.5s;
  transform: translateY(8px);
  visibility: visible;
  bottom: 185px;
}

.multiplier-sec {
  text-align: center;
  background-color: #14161a;
  border-radius: 8px;
  padding: 10px;
  border: 1px solid #5a5a5a;
  position: absolute;
  width: calc(100% - 23px);
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 0;
  transform: translateY(160%);
  transition: 0.5s;
}

.multiplier-sec button {
  background-color: #212121;
  border: 0;
  border-radius: 6px;
  padding: 4px 20px;
  text-align: center;
  color: #fff;
  border: 1px solid #3e3e3e;
}
.multiplier-sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.multiplier-sec button.active {
  background-color: #2fc2ea;
  color: #fff;
}

.login-form input {
  color: #fff;
}

.header-container {
  position: sticky;
  top: 0;
  background-color: #000;
}

.main-window {
  background-color: #000000;
  height: calc(100vh - 0);
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #000000;
  --bs-btn-border-color: #000000;
}

.footer-btns .active-btn span.counts {
  color: #000;
}

input.search-bar {
  background-color: #000000;
  border: 1px solid #2b2b2b;
  width: 250px;
  height: 35px;
  border-radius: 8px;
  padding-left: 34px;
  margin-bottom: 20px;
  color: #b1b1b1;
  font-size: 13px;
}



.search-box span {
  position: absolute;
  left: 10px;
  top: 5px;
  color: #b1b1b1;
}
.search-box {
  /* display: flex;
    left: 5px;
    flex-direction: row-reverse; */
  position: relative;
}


button.back-btn {
  border: 0;
  background-color: #fff;
  box-shadow: none;
  border-radius: 5px;
  font-size: 16px;
  padding: 2px 12px 2px 5px;
  margin: 10px 0px;
}
button.back-btn svg {
  font-size: 18px;
}
.stockMarket-radio {
  width: 15px;
  height: 15px;
  margin-left: 3px;
}

.success-message {
  color: #fff;
  text-align: center;
  background-color: var(--blue);
  padding: 4px 8px;
  padding-bottom: 1px;
}

/* .border{
  border:1px solid white;
  padding:px;
} */

.buttontext {
  font-size: 15px;
}

.buttontext1 {
  font-size: 15px;
  color: #3e86f1;
}

.error-message {
  color: #fff;
  text-align: center;
  background-color: rgb(168, 65, 65);
  padding: 4px 8px;
  padding-bottom: 1px;
}

.cancelbtn {
  border: grey;
  background-color: white;
  color: rgb(102, 100, 100);
  font-weight: 700;
}
.cancelbtn:hover {
 
  background-color:white;
  color: rgb(102, 100, 100);
 
}
.main_text {
  margin-right: 20px;
}
.main_input {
  border: none;
  padding: 1rem;
  margin-top: 2rem;
  font-size: 1.6rem;
  border-bottom: 0.2rem solid #bdbdbd;
  outline: none;
}

.main_label {
  padding-left: 1rem;
  color: #bdbdbd;
  transform: translateY(4.8rem);
  transform-origin: left top;
  cursor: text;
}

.main_input,
.main_label {
  transition: 0.2s ease-in-out;
}
.stoploss label {
  color: white;
}
.main_inputt:focus,
.main_input:not(:placeholder-shown) {
  border-bottom: 0.2rem solid #212121;
}
.sideNav {
  height: 100%; /* 100% Full-height */
  width: 50%; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 10%; /* Stay at the top */
  right: 0;
  background-color: #1d2027; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  /* padding-top: 10px; */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
}
.sideNav2 {
  height: 100%; /* 100% Full-height */
  width: 25%; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 2; /* Stay on top */
  top: 0; /* Stay at the top */
  right: 0;
  background-color: #1d2027; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  /* padding-top: 10px; */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
}
#tradeView {
  transition: 0.5s;
}
.main_input:focus ~ .main_label,
.main_input:not(:placeholder-shown) ~ .main_label {
  padding: 0;
  color: blue;
  transform: translateY(2rem) scale(0.8);
}
@media (max-width: 767px) {
  .mid_div {
    width: 50%;
  }
  .mid_btn {
    display: flex;
    justify-content: flex-start;
    align-items: start;
  }
  .btn_1 {
    margin-right: 5px;
  }
}

@media only screen and (max-width: 320px) and (orientation: portrait) {
  button.sell-btn,
  button.buy-btn {
    font-size: 15px;
  }
  .trade-btn {
    padding: 5px 18px;
  }
  button.timer-btn {
    padding: 6px;
  }
  .selector {
    bottom: 109px;
  }
  .trade-btns {
    bottom: 57px;
    padding: 6px 12px;
    padding-top: 6px;
  }
  .multiplier-sec button {
    padding: 4px 20px;
    font-size: 11px;
  }

  .multiplier-sec.show {
    bottom: 158px;
  }
  .widget-class iframe {
    height: 40vh !important;
  }
}

.MuiPaper-elevation .MuiBox-root button svg {
  font-size: 20px;
}

.MuiPaper-elevation .MuiBox-root button {
  box-shadow: none;
  border: 0;
  background: none;
}
.MuiPaper-elevation .MuiBox-root {
  padding: 15px 6px;
}

.MuiPaper-elevation .logout-btn {
  position: absolute;
  bottom: 100px;
  left: 0;
  right: 0;
  line-height: 29px;
  font-weight: 500;
  border-radius: 22px 22px 0px 0px;
}

button.dark.logout-btn {
  padding: 7px;
  font-weight: 500;
  border-radius: 22px 22px 0px 0px;
}

.dark-theme button.dark.logout-btn {
  color: #ffffff;
  border: 2px solid #41414161;
  background-color: #41414161;
}

.dark-theme .drawer-heading {
  border-bottom: 1px solid #41414161;
}

.drawer-heading span {
  position: absolute;
  right: 8px;
  top: 8px;
}
.drawer-heading h3 {
  font-size: 18px;
  padding-bottom: 0;
  margin-bottom: 0;
}
.dark-theme .drawer-heading {
  border-bottom: 1px solid #41414161;
}
.dark-theme .drawer-heading {
  color: #fff;
}

.drawer-heading {
  text-align: left;
  padding: 12px;
  font-size: 18px;
}
.account-li {
  margin-bottom: 10px;
}

.account-li span {
  display: block;
}
.dark-theme .account-li span.d-block.username {
  color: #fff;
  font-size: 17px !important;
}
.dark-theme .account-li span.d-block.points {
  color: #0094ff;
  font-weight: 800;
}
.dark-theme .MuiDrawer-paperAnchorRight,
.dark-theme .MuiDrawer-paperAnchorLeft {
  background-color: #000;
}

.dark-theme .account-li b {
  font-size: 16px;
}

.dark-theme .account-li span {
  color: #acacac;
  font-size: 14px;
}
.dark-theme .account-li {
  color: #ffff;
  background-color: #41414161;
  padding: 7px;
  border: 2px solid #41414161;
  border-radius: 6px;
}

.accounts svg {
  color: #fff;
  font-size: 40px;
  margin-right: 15px;
}
.accounts span.d-block.points svg {
  position: absolute;
  top: -19px;
  line-height: 0;
  font-size: 29px;
}
.accounts span.d-block.points {
  color: #0094ff;
  font-weight: 800;
  padding: 0;
  line-height: 10px;
  position: relative;
  top: 5px;
}
.account-menu .account-li {
  padding: 10px;
  padding-bottom: 16px;
}

.account-menu {
  padding: 12px;
}

.accounts svg {
  color: #fff;
  font-size: 32px;
  margin-right: 15px;
  position: relative;
  top: 1px;
}

.accounts span.d-block.username {
  color: #fff;
  line-height: 12px;
}
.username-selected{
  color: #0094ff !important;
}



.container button.menu-icon {
  justify-content: end;
  padding-left: 0;
}

.full-w .MuiPaper-elevation {
  width: 100vw;
}

.inner-content .MuiInputBase-root {
  background-color: #1a1a1a;
  margin: 10px 0px;
  outline: none;
  border: 1px solid #ababab;
  color: #fff;
}
.inner-content .MuiInputBase-input {
  background-color: #1a1a1a;
}
.inner-content .MuiFormControl-root {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding: 5px 15px;
}
.MuiPaper-elevation .MuiBox-root button {
  color: #fff;
}
.inner-content label.MuiFormLabel-root.MuiInputLabel-root {
  padding: 16px;
  color: #fff;
}
.inner-content {
  padding: 25px 0px;
}
.inner-content .MuiInputLabel-shrink {
  padding: 16px;
  color: #fff !important;
  left: -8px;
  top: -10px;
}
.container button.menu-icon {
  min-width: auto;
}
button.change-password {
  background-color: #fff !important;
  color: #000 !important;
  font-weight: bolder !important;
  font-size: 15px;
  width: calc(100% - 30px);
  padding: 10px;
  margin: 10px 15px;
}
.wallet-header svg {
  color: #fff;
  font-size: 29px;
  position: absolute;
  right: -27px;
  top: 1px;
}
.account-menu .account-li img {
  filter: invert(100);
}


.light-mode img {
    filter: invert(1);
}
.light-mode {
    filter: invert(1);
} 

.light-mode .positive{
  filter: invert(100);
} 

.light-mode .negative{
  filter: invert(100);
} 




.MuiSwitch-track {
  background-color: white !important;
}

.menu-bar {
  color: white;
  font-size: 33px !important;
}

.tab-btns .active-btn {
  background-color: #000000 !important;
  color: #2cb9e4;
}

.tab-btns button.btn.btn-secondary {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
  height: 36px;
  line-height: 36px;
  padding: 0;
}
/* .success-message {
  font-family: 'Montserrat', sans-serif;
} */

.set_mar {
  margin: 15px 0 15px 0;
}
.mob_set span {
  padding: 5px;
}

.mob_set span pending-limit_btnn{
  padding: 0px;
}


.sweet-alert + div {
  width: 300px !important;
  height: 320px !important;
}
.loading {
  position: fixed;
  width: 100vw;
  overflow: hidden;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
}
@media (max-width: 600px) {
  .set_font {
    font-size: 12px;
    padding: 2px;
  }
  .set_font1 {
    font-size: 11px;
    padding: 2px;
    margin-left: 7px;
    width: 56px;
  }

  .mob_set1 {
    flex-direction: column;
    display: flex;
    width: 24%;
    margin-left: 0;
    grid-gap: 10px;
    gap: 10px;
  }
}

@media (max-width: 767px) {
  .avgPrice {
    color: grey;
    font-size: 13px;
    line-height: 18px;
    font-weight: 500;
    letter-spacing: .12px;
    color: #959595;
  }
  #tradeView {
    width: 100%;
  }
  .headerbox {
    /* border:1px solid white; */
  }

  .image {
    border: 1px solid blue;
  }
  .headerbox h6 {
    font-size: 14px;
  }
  .card-body {
    padding: 0px !important;
  }
  .headerbox p {
    /*padding: 0 6px;*/
    font-size: 13px;
  }
  .headerbox img {
    padding-right: 7px !important;
  }

  .info {
    padding: 1px;
    margin: 10px 0px;
  }

  .button {
    justify-content: space-between;
  }

  .sellandbuybtn {
    border: none;
  }

  .individual-card {
    border-top: 2px solid white;
  }

  .avgPriceval {
    font-size: 14px;
    color: white !important;
  }

  .cell {
    margin-left: 15px;
  }



  .btnbs {
    /* width: 36px;
  padding: 2px 8px; */
   /* padding: 0px 8px;*/
    font-size: 15px;
  }

  .title {
    font-size: 12px;
    /* color:rgb(189, 188, 188) !important; */
  }

  .price {
    margin-bottom: 0px;
    font-size: 13px;
    color: white;
  }
  .price-block {
    gap: 4px;
    justify-content: end;
  }

  .line {
    margin-top: 2px;
    border-bottom: 0.5px solid #7f7f7f;
    padding: 8px 0px;
  }

  .assest-name {
    font-size: 13px;
    color: white;
  }

  .display-time {
    font-size: 12px;
    color: grey;
  }

  .detail {
    font-size: 12px;
    color: grey;
    margin: 0px;
    font-weight: 600;
  }

  .value {
    font-size: 14px;
    color: white !important;
    font-weight: 500;
  }

  .tabs {
    color: white !important;
  }

  .buy-btn {
    font-size: 14px;
    padding: 3px 5px;
   
    margin-left: 30px;
    border-radius: 5px;
    color:  var(--green);
    font-weight: bold;
    text-transform: uppercase;
  }

  .short_cell-btn {
    padding: 3px 5px;
    color: red;
   
    border-radius: 5px;
    font-size: 14px;
    margin-left: 40;
    font-weight: bold;
    text-transform: uppercase;
  }

  .executed-buy-btn {
    font-size: 14px;
    padding: 3px 5px;
    margin-left: 24px;
    margin-left: 30px;
    border-radius: 5px;
    color:  var(--green);
    font-weight: bold;
    text-transform: uppercase;
  }

  .holding-buy-btn {
    color:  var(--green);
  }
  .sideNav {
    height: 100%; /* 100% Full-height */
    width: 0; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    z-index: 1; /* Stay on top */
    top: 0; /* Stay at the top */
    left: 0;
    background-color: #fff; /* Black*/
    overflow-x: hidden; /* Disable horizontal scroll */
    /* padding-top: 10px; */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
  }
  .executed_shoret-cell {
    padding: 3px 5px;
    color: red;
   
    border-radius: 5px;
    font-size: 14px;
    margin-left: 30px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .cancel-btn {
    padding: 1px 10px;
    color: white;
    font-size: 12px;
  }

 /* .negative {
    color: rgb(167, 164, 164) !important;
  }
    
   .positive {
    color: grey !important;
  }
    */

  .negative {
    color:  var(--red) !important;
  }
  .positive {
    color:  var(--green) !important;
  }
 

  .small-text {
    color: #a3a2a2 !important;
    font-size: 11px;
  }

  .big-text {
    color: white;
    font-size: 13px;
  }

  .limit-btn {
    padding: 3px 5px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .heading-container {
    justify-content: space-between;
  }

  .closed-card-title {
    max-width: 100%;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0.1px;
    color: #fff;
    margin-bottom: 4px;
}

  .closed-card-subtitle {
    font-size: 12px !important;
    font-weight: 600 !important;
  }

  .closed-label {
    color: #959595;
    font-size: 13px;
    margin: 0px;
  }

  .closed-value {
    font-size: 14px;
    color: white;
  }
  .closed-box {
    margin-top: 15px;
  }

  .executed-title {
    font-size: 13px;
    margin-bottom: 0;
    margin-top: 2px;
}

  .executed-subtitle {
    font-size: 12px;
    color: grey;
  }

  .executed-label {
    font-size: 12px;
    color: grey;
    margin-bottom: 2px;
  }

  .executed-value {
    font-size: 13px;
    color: white !important;
  }

  /* .executed-part {
    padding-bottom: 5px;
    padding-top: 5px;
  } */

  .market-btn {
    width: 69px;
  }

  .learn-text {
    color: rgb(112, 140, 201);
    margin-top: 25px;
    font-size: 14px;
  }

  .holding-title {
    font-size: 14px;
    margin-bottom: 0px;
    color: white;
  }

  .holding-subtitle {
    margin: 0 !important;
  }

  .holding-percentage {
    margin-bottom: 0px;
  }

  .holding-title1 {
    font-size: 14px;
    color: #959595;
  }

  .holding-title1-container p {
    margin-bottom: 0;
  }

  .holding-image {
    margin-right: 10px;
  }

  .holding-short-cell {
    color:var(--red);
    font-weight: bold;
  }

  .holding-label {
    font-size: 13px;
    color: #c8c8c8;
    margin-bottom: 1px;
    font-weight: 500;
}



  .holding-title1-value {
    color: white;
    font-size: 14px;
  }

  .holding-per1 {
    font-size: 14px;
    color: white;
  }

  .holdig-val {
    font-size: 18px;
    font-weight: 500;
    color: white;
  }

  /* .holding-detailsval {
    margin-left: 28px;
  } */

  .footer-label {
    color: white;
  }

  .fotter-img {
    height: 30px;
    width: 30px;
  }

  /* .tournament-btn {
    margin-top: 30px;
  } */

  .name-header {
    color: white;
  }
}

.stock-info span {

  text-align: left;
}
/* New Css for mobile */

@media(max-width:767px)
{
  .trade-window .card-body .short_cell-btn, .trade-window .card-body .executed_shoret-cell {

    font-size: 10px;
  }


body .holding-wrap.all-hold .MuiTabs-flexContainer {
  justify-content: space-between !important;
}

.order-open + .search-box input {
  margin-bottom: 0px;
}

.search-box-order{
  margin-top: 60px;
}

.order-open + .search-box {
  margin-top: 60px;
}
body .holding-wrap.all-hold .MuiTabs-flexContainer .flash-message.show-header {
  margin-left: 0;
}
body .holding-wrap .MuiTabs-flexContainer .flash-message.show-header {
  margin-left: auto;
}
body .holding-wrap .MuiTabs-flexContainer {
  justify-content: unset;
}

.holding-wrap .tabs button.MuiButtonBase-root:not(.Mui-selected) {
  color: #fff;
  opacity: 1;
}
span.target-price {
  DISPLAY: BLOCK;
  margin-bottom: -4px;
}

body .container button.menu-icon svg {
  width: 27px;
}
.footer-main {
  padding-bottom: 4px;
}

body .holding-wrap .tabs .Mui-selected {
  color:var(--blue);
}
.main-footer svg {
  width: 19px;
  color: #fff;
}

body .MuiTabs-indicator {

  background-color: var(--blue);
}

  .card.mb-2.closed-box
  {
    background-color: var(--secondary-bg);
  }

  .date-text {
    font-size: 13px;
    margin-bottom: 0;
}
  p.closed-label
  {
    font-size: 13px;
    line-height: 18px;
    font-weight: 500;
    letter-spacing: .12px;
    color: #959595;
    margin-bottom: 0 !important;
  }
  .stock-info bdi {
    display: block;
}
.hide-it {
  display: none !important;
}
.tags-badges {
  display: flex;
  column-gap: 7px;
}

.tags-badges span.flash-message {
 margin-left: 0px;
}


body .mob_set.lp-type .short_cell-btn, body  .mob_set.lp-type .executed_shoret-cell, body .trade-window .card-body .buy-btn, body .trade-window .card-body .executed-buy-btn {
  width: 75px;
  margin-left: 0;
}
.d-flex.custom_panding_heading {
  align-items: center;
  margin-bottom: 10px;
}
.executed-part h5.card-title.executed-title {
  margin-bottom: 0;
}
.card-body.executed-order .executed-stock {
  align-items: center;
  margin-bottom: 10px;
}
h6.card-subtitle .hide-pc {
  background-color: #ff00005e;
  position: absolute;
  top: 17px;
  right: 21%;
  padding: 2px 11px;
  border-radius: 55px;
  color: red;
  font-size: 11px;
}
body .executed-value {

  margin-bottom: 0;
}
body .main-footer .footer-btns > * {
  width: 25%;
}
.closed-card-title {
  max-width: 100%;
}
.holding-main .card{
  background: var(--secondary-bg);
}
/* .holding-main .card, body .closed-holding .card-body{
  background: var(--secondary-bg);
} */
.trade-window .closed-holding .card .card-title.holding-title {
  display: flex;
  width: 21%;
  justify-content: right;
}

.trade-window .card-body {
  padding: 15px 12px !important;
}
.d-flex.custom_panding_heading .d-flex:first-child {
  align-items: center;
}
.trade-window .card-body h5.card-title.assest-name {
  margin-bottom: 0;
}

.hide-mbl
{
  display: none;
}
.main-footer {
  height: 60px;
}
.trade-window .holding-main .card-body {
  padding: 6px 6px !important;
}
.holding-stats .holding-per1 {
  text-align: right;
  margin-right: auto;
}
.trade-window .holding-main .card-body .card-text .avgPrice h6 {
  padding-top: 2px;
}
.trade-window .closed-holding .card .card-title.holding-title {
  display: flex;
}

.trade-window .closed-holding .card .card-title.holding-title svg {
  width: 18px;
  height: 18px;
  margin: 0;
  padding: 0;
}
.trade-window .holding-main .card-body .card-text {
  margin-top: 10px;
}
.trade-window .closed-holding .card-body .card-text {
  margin-top: 10px;
}

.trade-window .holding-main .card-body .card-text .avgPrice {
  margin-bottom: 0;
}

/* .trade-window .closed-holding .card-body .card-text p.closed-value {
  margin-bottom: 5px;
} */

.holding-stats-main .holdig-val {
  margin-bottom: 0;
}
.holding-stats .holding-per1 svg {
  width: 23px;
  height: 23px;
  position: relative;
  top: -3px;
}
.closed-asset-name {
  max-width: 70%;
  width: 70%;
}

.trade-window .holding-main .card-body .holding-stock {
  max-width: 45%;
  width: 45%;
}


.main-window {
  background-color: #000000;
  height: calc(100vh - 47px);
}

main {
  height: calc(100vh - 60px);
}
.search-box {
  /* margin-top: 20px; */
  margin-top: 5px;
}
.success-message .top_message .stock-info img {
  border-radius: 55px !important;
  object-fit: cover !important;
  width: 36px !important;
  height: 36px !important;
  padding: 0 !important;
  margin-right: 8px;
}
.stock-info {
    display: flex;
    align-items: center;
}
  .assets-img img {
    padding: initial !important;
    border-radius: 55px !important;
    min-width: 30px;
    min-height: 30px;
    max-width: 30px;
    position: relative;
    right: 8px;
    margin-top: 2px !important;
}
.asset-price svg {
  width: 18px;
  height: 18px;
  vertical-align: top;
}
#tradeView .price-block {
  position: relative;
  left: 7px;
}
span.title.small-text {
  line-height: 1.1;
  display: block;
  font-weight: 600;

}
.price-block bdi {
  font-weight: 600;
  color: #80808054;
  line-height: 18px;
}
.price-block {
  font-weight: 600;
}
.sector {
  margin-top: 6px;
  font-weight: 600;
}

.sweet-alert h2 {
  font-size: 17px !important;
  margin-bottom: 24px;
  display: block;
  top: -8px;
}
.sweet-alert:first-child > div:first-child {
  width: 100% !important;
  height: 127px !important;
  margin: 0 !important;
  padding: 0 !important;
}
.succ-stock {
  padding: 17px 12px;
  font-size: 15px;
}
.trade-window .place-order-popup .card-body {
  padding: 0 !important;
}
.holding-detailsval {
  text-align: center;
}

.trade-window .tab-btns {
  padding: 0;
}
}


.quanity-price input::placeholder {
  color: #74747438  !important;
  font-size: 13px;
}
.stoploss-checkboxes bdi {
  color: #ffffff85;
  padding-left: 5px;
}
.quanity-price input {
  background: #74747438 !important;
  border: 0;
  padding: 16px;
  height: 36px;
  border-radius: 4px;
  color: #fff;
  font-size: 13px;
}
.quanity-price label {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 3px;
}
.stock-info span {
  font-size: 13px;
  font-weight: 700;
}
span.back svg {
  font-size: 20px;
}

span.back {
  font-weight: 800;
  margin-right: 5px;
  font-size: 15px;
  position: relative;
  right: 3px;
}
.market-limit input {
  position: absolute;
  opacity: 0;
}
.market-limit label {
  text-transform: uppercase;
  color: #fff;
  padding: 0;
  margin: 0;
  min-height: auto;
  border-radius: 4px !important;
  height: auto;
  line-height: 30px;
  font-size: 12px;
}
.market-limit input:checked + label {
  background-color: var(--blue);
  color: #fff;
  text-align: center;
  border-radius: 4px;
}
.margin-charges button {
  padding: 8px 12px;
  font-size: 15px;
  border-radius: 4px;
  text-transform: capitalize;
  font-weight: 500;
}

.margin-charges {
  font-size: 14px;
}

.stoploss-checkboxes input[type="checkbox"] + span {
  font-size: 15px;
  font-weight: 500;
}

.stoploss-checkboxes input[type="checkbox"] {
  width: 15px;
  height: 15px;
  margin: 0;
  position: relative;
  top: -1px;
}

.stoploss-checkboxes input[type="number"].enabled::placeholder {
  color: #ffffff70 !important;
  font-size: 12px;
}

.stoploss-checkboxes input[type="number"].enabled {
  background: #292929 !important;
  border: 0;
  font-size: 12px;
  height: 26px;
  padding-left: 9px;
  color: #fff;
  border-radius: 4px;
}
.margin-charges p {
  margin-bottom: 0;
  padding-left: 6px;
  font-weight: 500;
}
.stoploss-checkboxes input[type="number"]::placeholder {
  color: #484848;
}

.stoploss-checkboxes input[type="number"] {
  background-color: black;
  border: 1px solid #312f2f;
  font-size: 12px;
  height: 26px;
  padding-left: 9px;
  color: #fff;
  border-radius: 4px;
}

.succ-stats, .succ-stock, .grey-border {
  border-color: #5e616b !important;
}

.succ-stock > div {
  font-weight: 500;
}
.succ-stock .MuiChip-colorSuccess {
  background-color: #00800052;
  color:  var(--green);
  padding: 0px 9px;
  height: 30px;
  font-weight: 600;
}

.succ-stats {
  padding: 13px 2px;
}

.main-holding-row {
  width: 100%;
  justify-content: space-between;
}



.main-holding-row .holding-percent {
  display: flex;
}

.main-holding-row .holding-percent svg {
  width: 18px;
  height: 18px;
  margin: 0;
  padding: 0;
}
.avgPrice h6 {
  padding-top: 5px;
}
.holding-stock .btn-container {
  right: 0;
  left: auto;
  top: 24px;
}

.holding-stock .btn-container .short_cell-btn, .holding-stock .btn-container .buy-btn
{
  font-size: 12px;
}


.top-holding .holding-per1 {
  display: flex;
  align-items: center;
  justify-content: end;
}
.top-holding .holding-per1 svg {
  width: 19px;
  height: 19px;
}
.top-holding .holding-per1 {
  text-align: right;
}

.trade-window .card-body {
  padding: 15px 12px !important;
}
.trade-window .card-body .short_cell-btn, .trade-window .card-body .executed_shoret-cell {
  background-color: #ff000033;
  text-align: center;
  font-size: 9px;
  border-radius: 3px;
  color: var(--red);
  padding: 3px 8px;
  line-height: 14px;
  min-width: 75px;
  
}
.mob_set.lp-type .short_cell-btn, .mob_set.lp-type .executed_shoret-cell {
  width: 67px;
  margin-left: 0;
}
.trade-window .card-body .buy-btn, .trade-window .card-body .executed-buy-btn {
  background-color: #4184f34a;
  text-align: center;
  font-size: 9px;
  border-radius: 4px;
  color: var(--blue);
  padding: 3px 8px;
  line-height: 14px;
  margin-left: 0px;
}
.mob_set.o-type {
  max-width: 67px;
  /* margin-left: auto;
  margin-right: 10px; */
}

.trade-window .card-body .buy-btn, .trade-window .card-body .executed-buy-btn{
  min-width: 67px;
}
.mob_set.lp-type {
  max-width: 90px;
  width: auto;
}
.trade-window .card-body .pending-limit_btnn {
  text-align: center;
  font-size: 10px;
  border-radius: 3px;
  padding: 3px 8px;
  line-height: 14px;
  font-weight: 100;
  text-transform: capitalize;
  min-width: 64px;
  vertical-align: 2px;
  display: block;
}

/* .trade-window .card-body  .mob_set {

  width: auto;

} */
.trade-window .card-body .card-text .value {
  margin-top: 3px;
  margin-bottom: 0;
}

.trade-window .card-body .card-text {
  margin-top: 1px;
}
.trade-window .card-body .custom_panding_heading img {
  min-height: 33px;
  margin-right: 7px;
  padding: 0;
  height: 33px !important;
  max-width: 33px;
  padding: 0 !important;
}


button.gmail-btn img {
  width: 100%;
}

button.gmail-btn {
  background: none;
  padding: 0;
  border: 0;
}

.login-part span {
  text-align: center;
  display: block;
}

.register-btn {
  width: auto;
  padding: 0px 20px;
  margin: 0;
  height: 35px;
  line-height: 35px;
  margin-left: 10px;
}
.sweet-alert {
  width: 85% !important;
}
.success-message .top_message img {
  margin-right: 7px !important;
}
.selected-stock .price-block {
  flex-direction: column;
  gap: 0;
}

.selected-price {
  display: flex;
  column-gap: 6px;
}

.top_message {
  justify-content: space-between;
}
.selected-price p {
  margin-bottom: 0;
  padding-bottom: 0;
}

.selected-price {
  align-items: center;
}
.b-r {
  border-right: 1px solid #353535;
}
.market-limit label {
  border: 1px solid var(--blue);
  color: var(--blue);
  width: 100%;
}
.market-limit {
  justify-content: space-between;
}

.market-limit > * {
  width: 100%;
}

span.limit-wrap {
  margin-right: 15px;
}

/* Desktop and large devices css */

@media(min-width:768px)
{
  .closed-box {
    background-color: #000;
}

  body .holding-start.holding-parent {
    margin-top: 22px;
}
  .dashboard-ftx-wrap {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
    column-gap: 30px;
}
body .stock-info span {
  font-weight: 500;
}
body .dashboard-sec svg {
  font-size: 18px;
}

body .dashboard-sec {
  font-size: 13px;
  margin-bottom: 7px;
  text-decoration: underline;
  cursor: pointer;
}

body .taxes-stats {
  border: 0 !important;
}
body .dashboard-money-img img {
  width: 60px;
}
body .dashboard-1, body .dashboard-2 {
  width: 48%;
}
body .top-holding svg {
  top: -1px;
  width: 19px;
  height: 19px;
}
.dashboard-ftx:last-child {
  margin-top: 14px;
}
.top-holding p.holding-title1 {
  margin-bottom: 0;
  font-size: 13px;
  color: #868686;
}


body .dashboard-ftx .holding-start .holding-stats-main big {
  font-size: 19px;
}


  body .holding-start .holding-stats-main {
    background-color: #000000 !important;
    margin-top: 0 !important;
}

body .holding-wrap.dashboard-stats {
  padding: 20px !important;
}
  .account-menu {
    max-width: 400px;
}
  .account-drawer .MuiDrawer-paperAnchorLeft {
    max-width: 400px;
}

  body .flash-message.flash-msg-wrap .name-header {
    position: absolute;
    top: 3px;
    right: -1px;
    cursor: pointer;
}
.closed-holding .tax-tooltip .closed-box{
  margin-left: auto;
}
body .tax-tooltip {
  background-color: rgb(0 0 0);
  min-height: 80.98px;
  display: flex;
  align-items: center;
}
span.hide-mbl.red-btn {
  display: none;
}
.trade-window .holding-main .card {
  background: rgb(36, 38, 46);
}


.flash-msg-wrap.flash-message {
  max-width: calc(40% - 32px);
  position: absolute;
  top: 13px;
  left: 51px;
  right: auto;
  height: 66px;
  display: flex;
  align-items: center;
  justify-content: center;
}


  .wallet-header {
    margin-left: auto;
    padding-right: 55px;
}
.holding-wrap .search-box {
  margin-top: 12px;
  position: relative;
  width: 100%;
}
.holding-wrap .search-box + div.ms-1 {
  position: absolute;
  right: 0;
  top: 13px;
}

.holding-search {
  position: relative;
}
  .header-container {
    background-color: #1d2027;
    width: 100%;
    max-width: 100%;
}
.accounts span.d-block.points svg {
  right: -36px;
}
.red-btn {
  background-color: #ff000063;
  text-align: center;
  font-size: 10px;
  border-radius: 100px;
  color: #ff0000;
  padding: 3px 12px;
  line-height: 14px;
  min-width: 67px;
  height: auto !important;
  margin: auto;
  text-transform: capitalize;
}
  .executed-order h5.card-title.executed-title {
    font-size: 13px;
    margin-bottom: 0;
}
.hide-pc {
  display: none;
}

  .main-window {
    padding-left: 0;
}
.main-window {
  max-width: 100%;
}
  .stage1P1 {
    width: 100%;
    position: sticky;
    background-color: #1d2027;
    top: 90px;
    max-width: 80px;
    padding-top: 25px;
    height: 100vh;
}
.App {
  background-color: black;
}
.header_stage {
  background-color: #1d2027;
  padding: 8px 15px;
  margin-bottom: 12px;
}
/* .main-window .search-main {
  background-color: #1d2027;
} */
#tradeView {
  width: 100%;
  transition: 0.5s;
  background-color: #1d2027;
  margin-left: 12px;
  max-width: 40%;
  padding: 20px;
}
footer.footer_stage button {
  padding: 0;
  margin-bottom: 6px;
  font-size: 10px;
  font-weight: 400;
  text-transform: uppercase;
  margin-top: 6px;
}
footer.footer_stage img {
  width: 24px;
  height: 24px;
  object-fit: contain;
  margin-bottom: 5px;
}

footer.footer_stage button.active-btn img {
  filter: none;
}
footer.footer_stage button.active-btn span {
color: #0094ff; 
}
footer.footer_stage .btn img {
  filter: brightness(100);
}


footer.footer_stage button, footer.footer_stage button:active {
    outline: none;
    border: 0;
}

.place-order-popup {
  max-width: 40%;
  margin-left: auto;
  margin-left: 30px;
}

.selected-price p {
  margin-bottom: 0;
  padding-bottom: 0;
  font-size: 12px;
}


.holding-main .holding-stock .card-title {
  margin-bottom: 2px;
  font-size: 13px;
  font-weight: 500;
}
.holding-main .holding-stock {
  width: 42%;
}

.trade-window .holding-main .card {
  margin-bottom: 0 !important;
  padding-bottom: 4px !important;
}
.trade-window .holding-main  .hide-pc {
  display: none;
}
.trade-window .holding-main .card .card-body .main-holding-row {
  width: 100%;
}
.trade-window .holding-main .card .card-body .main-holding-row img {
  width: 30px;
  height: 30px !important;
}
.trade-window .holding-main .card .card-body h6.card-subtitle {
  font-size: 11px;
  padding: 0;
  margin: 0;
}
/* .trade-window .holding-main .card .card-body .main-holding-row {
  width: 82%;
} */
.trade-window .holding-main .card .card-body .holding-percent {
  margin: 0;
  margin-right: 6px;
}

.trade-window .holding-main .card-body .card-text h6 {
  font-size: 14px;
  color: #fff;
  margin-bottom: 0;
  font-weight: 400;
}
.trade-window .holding-main .card .card-body {
  padding: 4px !important;
}
.trade-window .holding-main .card-body .card-text {
  font-size: 11px;
  color: #b6b6b6;
}
.trade-window .holding-main .card-body .card-text {
  margin-top: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}
.trade-window .holding-main .card-body .card-text div .d-lg-block {
  margin: 0;
}
.trade-window .holding-all-btns .holding-btns {
  column-gap: 15px;
}
.trade-window .holding-main .card .card-body button {
  padding: 0px 5px;
  display: block;
  font-size: 11px;
  line-height: 23px;
}
.holding-stock .btn-container {
  right: 0;
  left: auto;
  top: 13px;
  position: absolute;
  left: 0;
  right: auto;
  left: 35%;
}
.holding-btns .col-5 {
  width: auto;
}

.holding-btns {

  justify-content: right !important;
  margin-left: auto;
  width: auto !important;
}


.holding-btns .col-5 {
  width: auto;
  margin-left: 10px;
}



.holding-main {
  margin-bottom: 8px;
}
.trade-window .holding-main .card-body .card-text div div svg {
  width: 18px;
  height: 20px;
}
.trade-window .holding-main .card-body .card-text div .holding-percentage {
  margin-bottom: 0;
  font-size: 12px;
}
.trade-window .holding-main .card-body .card-text div div {
  width: 50%;
}

.trade-window .holding-main .card-body .card-text div .avgPrice {
  margin-bottom: 0;
}
.trade-window .holding-main .card .card-body .holding-percent svg {
  height: 18px;
  width: 18px;
  padding: 0;
  object-fit: contain;
  top: 2px;
  position: relative;
}
.limit > span {
  background-color: #23537887 !important;
  color: #68b1f4 !important;
}


.holding-start .holding-per1 svg {
  height: 20px;
  width: 20px;
  position: relative;
  top: -3px;
}

.holding-btns:before {
  content: "";
  height: 3px;
  width: 80%;
  top: 11px;
  position: relative;
  border-radius: 50px;
}

.holding-wrap {
  background-color: #1d2027;
  max-width: calc( 100% - 12px) !important;
  margin-right: 0;
  padding-bottom: 2px;
}


.holding-start  p.holdig-val {
  margin-bottom: 0;
  font-size: 16px;
}

.holding-start  p.holding-label {
  margin-bottom: 1px;
  font-size: 13px;
  color: #868686;
  font-weight: 600;
}
.dashboard-label .holdig-val {
  font-size: 16px;
  color: #fff;
  font-size: 17px;
}
.holding-search .search-box input.search-bar::placeholder {
  font-weight: 500;
  font-size: 13px;
}
.holding-search .search-box input.search-bar {
  margin-bottom: 11px;
 
}
.holding-start .holding-stats-main {
  padding: 14px 20px;
  /* width: 40%; */
  border: 0;
  margin-bottom: 0 !important;
}

.holding-wrap .holding-start.holding-parent .holding-stats-main {
   width: 30%; 
}

/* .holding-start .row.holding-stats .holding-image svg {
  color: #333 !important;
} */
/* .holding-start .card-text.d-flex.holding-detailsval > * {
  width: auto;
}

.holding-start .card-text.d-flex.holding-detailsval {
  width: 40% !important;
  justify-content: space-between !important;
} */
.holding-start .row.holding-stats .holding-image {
  margin-right: 10px;
}
.holding-start .holding-per1 {
  text-align: right;
  margin-right: auto;
  padding-left: 25px;
}
/* .holding-start .card-text.d-flex.holding-detailsval > * {
  width: 40%;
} */
body .holding-detailsval {

  border-bottom: 2px solid #3a3a3a;
}
.tab-btns .tabs .MuiTab-root {
  width: 20%;
  max-width: 240px;
}
.flast-n-tags {

  column-gap: 5px;
}
.holding-start .holding-stats-main {
  background-color: #24262e !important;
}
.holding-start .card-text.d-flex.holding-detailsval > *:last-child {
  padding-left: 1px;
 /* padding-left: 8px; */
}
.holding-start p.holding-title1.pl-title {
  margin-bottom: 0;
}

.holding-start p.holding-title1-value {
  margin-bottom: 0;
}

/* .holding-start .row.holding-stats .holding-assets-info {
  width: 32% !important;
  justify-content: space-between !important;
} */

.holding-start .row.holding-stats p.holding-title1 {
  margin-bottom: 0;
  font-size: 13px;
  color: #868686;
}

.holding-start p.holding-title1-value
{
  font-size: 14px;
}
body .dashboard-label .holdig-val {
  font-size: 16px;
  font-weight: 500;
}
body p.holding-title1-value big {
  font-size: 16px;
}
.holding-start .holding-stats {
  /* display: flex; */
  align-items: center;
  justify-content: space-between;
}

.holding-search {
  justify-content: space-between;
}

.trade-window .holding-main .card-body .card-text div {
  text-align: left !important;
  align-items: center;
  justify-content: space-between !important;
}
.closed-asset-name  h6.card-subtitle {
  font-size: 12px;
}
.flast-n-tags {
  position: absolute;
  bottom: 11px;
}
.closed-asset-name h5.card-title {
  font-size: 13px;
  margin-bottom: 20px;
}
.closed-holding p.closed-value {
  margin-bottom: 0;
  font-size: 15px;
}
.closed-holding .closed-box .col-4 > * {
  width: auto;
  text-align: left !important;
}

.closed-holding .closed-box .col-4 {
  width: 33%;
  flex-direction: row !important;
  justify-content: space-around;
}
.closed-holding .card-text.d-flex.closed-box {
  width: 60%;
  justify-content: right;
}

.closed-asset-main .closed-asset-name {
  width: 72%;
}
button.cancel-btn {
  font-size: 12px;
  border: 0;
  background-color: #ffffff;
  color: #000;
  padding: 3px 10px;
  margin-top: 20px;
  border-radius: 3px;
  font-weight: 600;
}


span.cancelled-btn {
  font-size: 12px;
  border: 0;
  background-color: var(--red);
  color: #fff;
  padding: 3px 10px;
  margin-top: 20px;
  border-radius: 3px;
  font-weight: 600;
  display: block;
}

.closed-asset-main {
  width: 38%;
}
body span.flash-message {
  min-width: 70px;
  max-width: 70px;
  display: block;
  margin-left: 0px;
font-weight: 500;

}
.holding-wrap .MuiTabs-flexContainer div:last-child {
  margin-left: auto;
}

.holding-wrap .MuiTabs-flexContainer {
  justify-content: left;
}

h6.card-title.executed-title {
  margin-bottom: 0px;
  font-size: 13px;
  font-weight: 500;
  margin-top: 0px;
}
h6.closed-value.date-text {
  margin-bottom: 0;
  font-weight: 400;
}

.trade-window .holding-main .card {
  background-color: black;
}



.closed-holding p.closed-label {
  font-size: 12px;
  margin-bottom: 2px;
  color: #b6b6b6;
}
.closed-asset-main .card-title.holding-title svg {
  width: 17px;
  height: 17px;
  padding: 0;
  margin-left: 0px;
  position: relative;
  top: -2px;
}
.closed-wrap .search-box input {
  margin-bottom: 12px;
}
.closed-asset-main .card-title.holding-title {
  margin-left: 35px;
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 0;
}
.closed-holding .card-text.d-flex.closed-box {
  flex-direction: row;
}
.trade-window .closed-holding .card-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card-body.pending-body {
  display: flex;
  justify-content: space-between;
}
.pending-body h5.card-title.assest-name {
  font-size: 13px;
  margin-bottom: 0;
  line-height: normal;
  height: auto;
}
.pending-details {
  width: 60%;
}


.card-text.d-flex.pending-details > * {
  width: 89px;
  text-align: center;
}

.card-text.d-flex.pending-details > *:first-child {
  width: 135px;
}


.pending-details  .col-4.d-flex.justify-content-center.align-items-center.flex-column {
  text-align: left !important;
  display: flex !important;
  align-items: flex-start !important;
}
.card-text.d-flex.pending-details {
  width: 60%;
  display: flex;
  justify-content: space-between;
  column-gap: 16px;
}
.pending-details p.value {
  font-size: 14px;
  color: #fff !important;
  margin-top: 0 !important;
  line-height: 24px;
}
.pending-details .col-4 p.detail {
  margin-bottom: 0;
  font-size: 12px;
  color: #9c9999;
}

.pending-body .custom_panding_heading> .d-flex {
  align-items: center;
}
body .tags-badges {
  display: flex;
  align-items: center;
  column-gap: 9px;
  margin-top: 3px;
}

body .trade-window .card-body .buy-btn, body .trade-window .card-body .executed-buy-btn {
  min-width: 70px;
  max-width: 70px;
}

body .mob_set.lp-type .short_cell-btn, body .mob_set.lp-type .executed_shoret-cell, body .trade-window .card-body .pending-limit_btnn {
  min-width: 70px;
}

body .mob_set.o-type {
  max-width: 67px;
  margin-left: 0;
  margin-right: 0;
}
.pending-body .custom_panding_heading {
  width: 35%;
}
.executed-order {
  display: flex;
  justify-content: space-between;
}
.executed-order p.executed-label {
  margin-bottom: 0;
  font-size: 12px;
  color: #9c9999;
}
.holding-wrap .search-box input {
  margin-bottom: 12px;
}

.tab-btns .tabs .MuiTab-root {
  margin-top: 12px;
  border-radius: 10px;
  padding: 0px 17px;
  height: 40px;
  min-height: auto;
  border: 1px solid #424242;
  line-height: 41px;
  margin-right: 20px;
}

.tab-btns .tabs .MuiTab-root.Mui-selected {
  background-color: white;
  border-color: #fff;
  color: #000;
}
.tab-btns .tabs .MuiTabs-indicator {
display: none;
}
.tab-btns > div > .MuiBox-root {
  border-bottom: 0 !important;
}
.holding-wrap .search-box {
  margin-top: 12px;
}
.executed-order .holding-detail > * {
  display: flex;
  width: 25%;
  align-items: start !important;
}



.executed-order  p.executed-value {
  font-size: 16px;
  margin-bottom: 0;
}
.executed-order  h5.card-title.executed-title {
  font-size: 13px;
}
.executed-order .holding-detail {
  width: 60%;
  justify-content: space-between;
  display: flex;
}
.executed-order .holding-detail > * {
  width: 75px;
}

.executed-order .holding-detail > *:first-child {
  width: 160px;
}
.executed-order  .executed-stock {
  width: 35%;
}
.executed-order  .executed-stock {
  display: flex;
  align-items: center;
}

div#tradeView .search-box {
  width: 100%;
}
div#tradeView p.name-header {
  font-weight: 900;
  font-size: 14px;
}
div#tradeView p.d-block.price {
  font-size: 15px;
  margin-bottom: 0;
}
div#tradeView .asset-padding.all-assets.line img {
  padding: 0 !important;
  margin-right: 10px;
}
.executed-order .executed-stock img {
  padding: 0 !important;
  margin-right: 10px;
}
div#tradeView .asset-padding.all-assets.line {
  border-bottom: 1px solid #ffffff29;
  padding-top: 8px;
  padding-bottom: 5px;
}
.place-order-popup .stock-info {
  display: flex;
  align-items: center;
}
div#tradeView .asset-padding.all-assets:hover {
  cursor: pointer;

}

.quanity-price > *, .stoploss-checkboxes > * {
  width: 48%;
}

.quanity-price, .stoploss-checkboxes {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.stoploss-checkboxes > *  .w-25 {
  width: 33%!important;
}
.sweet-alert h2 {
  font-size: 19px !important;
  margin-bottom: 17px !important;
  display: block;
}
.sweet-alert a.btn.btn-lg.btn-secondary {
  margin-right: auto;
}
.main-window {
  min-height: 100vh;
}
.sweet-alert>div {
  background-size: 200px !important;
  min-height: 135px !important;
  width: 100% !important;
  padding-top: 0 !important;
  margin-top: 0 !important;
}
.sweet-alert a.btn.btn-lg.btn-primary {
  margin-left: auto;
}
.sweet-alert a.btn.btn-lg.btn-primary, .sweet-alert  a.btn.btn-lg.btn-secondary {
  width: 46% !important;
}

.sweet-alert {
  max-width: 30% !important;
}

.succ-stats{
  color:#6046cf;
  size: 12px;
  box-shadow: none;
  border-color: #6046cf;
  width: 100%;
  margin-bottom:10px;
  margin-right: 0px;
}
.stoploss-checkboxes > * span {
  margin-right: 10px !important;
}
.place-order-popup .stock-info bdi {
  display: block;
}
div#tradeView .title.big-text {
  font-size: 13px;
  line-height: 1;
}

div#tradeView .price-block svg {
  width: 16px;
  height: 16px;
  position: relative;
  top: -3px;
}

div#tradeView .price-block bdi {
  color: #7c7676;
  font-size: 12px;
  padding: 0px 6px;
}
div#tradeView .price-block > p {
  font-size: 13px;
}

div#tradeView .small-text {
  color: grey;
  font-size: 11px;
  line-height: 1;
}
.login-form {
  margin: 0 auto;
}
button.gmail-btn.btn.btn-primary {
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
  display: block;
  margin-bottom: -20px;
}
.login-part h1 {
  text-align: center;
}
}

/* Desktop and large devices css */



/* New Milestone 1  */

span.name-header svg {
  font-size: 20px;
}

span.name-header {
  right: 5px;
  left: auto;
  top: 5px;
}
.search-box span svg {
  width: 16px;
}
.mid_btn button {
  border: 0;
}

.stock-wrap {
  align-items: center;
}

.stock-wrap .mid_btn.asset-row-btns {
  column-gap: 14px;
 
}

button.buy-initial {
  background-color:var(--blue);
  color: #fff;
      border-radius:4px;
      font-size:13px;
  padding:0px;
  width: 25px;
      height:21px;
  line-height:21px;
  
}

button.sell-initial {
  background-color: var(--red);
  color: #fff;
  border-radius: 4px;
  font-size: 13px;
  padding: 0;
  height: 21px;
  line-height: 21px;
  width: 25px;
  border: 0;
}


button.check-initial {
  background-color: var(--green);
  color: #fff;
  border-radius:4px;
  font-size:13px;
  padding:0px;
  width: 25px;
height:21px;
line-height:21px;
}

button.check-initial svg {
  font-size: 16px;
  padding: 0;
  width: auto;
  position: relative;
  top: -1px;
}

.flash-msg-wrap.red {
  background-color: var(--red);
}



.flash-msg-wrap.flash-message {
  color: #fff;
  font-size: 15px !important;
}

.flash-message.flash-msg-wrap .name-header svg {
  font-size: 20px;
  color: #ffffff;
}
.flash-message.flash-msg-wrap .name-header {
  position: absolute;
  top: 10px;
  right: 8px;
}
.flash-msg-wrap {
  background-color: var(--blue);
  padding: 10px;
  width: 96%;
  margin: 8px 2%;
}

.flash-message.flash-msg-wrap .name-header {
  min-width: 25px;
  min-height: 25px;
  max-width: 25px;
  max-height: 25px;
  line-height: 17px;
  text-align: center;
  padding: 0;
}


.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: var(--blue);
  --bs-btn-border-color: var(--blue);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: var(--blue);
  --bs-btn-focus-shadow-rgb: 49,132,253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--blue);
  --bs-btn-active-border-color: var(--blue);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: var(--blue);
  --bs-btn-disabled-border-color: var(--blue);
}

.btn-group {
  margin-bottom: 12px;
}
.selection-tab label {
  font-size: 13px !important;
  color: #fff;
}
.selection-tab {
  display: inline-block;
}
.selection-tab {
  height: 36px;
  text-align: center;
  width: 100%;
  /* min-width: 106px; */
  line-height: 36px;
  border-radius: 3px;
  padding: 0;
}

.place-order-popup .btn-group {
  justify-content: space-between;
  column-gap: 18px;
}

.place-order-popup .btn-group span.limit-wrap {
  margin: 0;
}

.selection-tab input + label {
  border: 2px solid #ffffff36;
  height: 36px;
  text-align: center;
  min-width: 156px;
  width: 100%;
  line-height: 34px;
  border-radius: 3px;
  padding: 0;
}
.selection-tab {
  position: relative;
}

.selection-tab input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.error-message + form .selection-tab span input:checked + label {
  color: var(--red);
  border-color: var(--red);

}

.success-message + form .selection-tab span input:checked + label {
  border-color: var(--blue);
  color: var(--blue);

}
.error-message + form .margin-charges p.buttontext1
{
  color: var(--red);
}
.form-switch .form-check-input {
  background-color: var(--red);
  border: 0px solid !important;
}

.quantitiy-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flash-msg-wrap.flash-message {
  color: #fff;
  font-size: 14px !important;
  padding: 8px 20px;
}
.quantitiy-wrap span {
  color: #888888;
  font-size: 12px;
  font-weight: 500;
}
.form-check-input:active {
  filter: none;
}
.form-switch .form-check-input, .form-switch .form-check-input:focus
{
  border-color:#000;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgb(255 255 255)'/%3e%3c/svg%3e");
}
.error-message .form-check-input:checked {
  background-color: var(--red);
  border-color: var(--red) !important;
}

.success-message .form-check-input:checked {
  background-color: var(--blue);
  border-color: var(--blue) !important;
}


.success-message + form .margin-charges p.buttontext1
{
  color: var(--blue);
}

/* .error-message + form .selection-tab {
  border-color: red;
} */
.place-order-popup {
    position: relative;
}


.place-order-popup .btn-group {
  margin-bottom: 0;
}

.market-title {
  display: block;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  margin-top: 15px;
}

.place-order-popup {
  margin-top: 30px;
}

/* .error-message .form-switch .form-check-input {
  background-color:#fff;
  border-color: var(--blue);
}

.success-message .form-switch .form-check-input:checked {
  background-color:#fff;
  border-color: var(--red);
} */





@media(max-width:767px)
{
  /* .asset-row-btns {
    position: relative;
    right: -13px;
} */
.dashboard-stats.dashboard-ftx-wrap {
  margin-top: 12px;
}
.menu-item {

  margin-bottom: 0;
}
.executed-stock .executed-part {
  max-width: 73%;
}
.flash-message.cancelled {
  position: absolute;
  top: 0;
  right: 8px;
}


}

footer.footer_stage .menu-item svg {
  width: 35px;
  height: 25px;
  margin-bottom: 4px;
}
.main-footer footer.footer_stage svg
{
  color: #fff;
}

.main-footer footer.footer_stage .active-btn svg {
  color: #0094ff;
}

.accounts span.d-block.username {
  text-align: left;
}


/* .account-sec {
  padding: 9px;
} */
.header-container {
  padding: 0 !important;
}
.notification svg {
  color: #fff;
}
.search-icon-header svg {
  color: #fff;
}
/* .container button.menu-icon {
  padding-top: 15px;
} */
.notification {
  margin-left: auto;
  margin-right: 23px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.search-icon-header {
  margin-left: auto;
  margin-right: 23px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.account-settings-icon{
  margin-right: 23px;

}
.quanity-price {
  justify-content: space-between;
  column-gap: 18px;
}

.quanity-price .inner-quantity {
  width: 100%;
}

.order-btns {
  column-gap: 18px;
}

.form-switch-wrap {
  position: absolute;
  right: 0;
  top: -29px;
  display: flex;
  font-size: 13px;
  align-items: center;
  column-gap: 2px;
}

.form-switch-wrap .form-switch {
  font-size: 16px;
}

.success-message .form-switch .form-check-input {
  background-color: var(--blue);
  border: 0px solid !important;
}



.desktop_stage .mid_btn button {
  text-align: center;
  align-items: center;
  justify-content: center;
}

.desktop_stage .mid_btn {
  text-align: center;
  align-items: center;
  justify-content: center;
}





/* holding page */

.holding-wrap button.MuiButtonBase-root.MuiTab-root {
  padding: 0;
  font-family: 'Roboto';
  font-size: 13px;
}

/* .holding-assets-info {
  padding-top: 12px;  

} */
.holding-detailsval {
  padding-bottom: 12px;  
  border-bottom: 2px solid #282828;
}
.holding-wrap .MuiTabs-flexContainer {
  justify-content: space-between;
  align-items: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}

.placeorder-margin-available{
  font-size: 12px;
  white-space: nowrap;
}

.placeorder-margin-required{
  font-size: 12px;
  white-space: nowrap;
}

.placeorder-charges{
  font-size: 12px;
  text-indent: 20px;
}
.action-btn-wrap {
  column-gap: 18px;
}


.action-btn-wrap .sellandbuybtn {
  width: 100%;
}

.btn-red, .btn-red:hover
{
  background-color: var(--red);
  padding: 3px 0px;
  font-size: 13px;
  border-radius: 4px;
  text-transform: capitalize;
  font-weight: 400;
  color: #fff;
}

.btn-primary, .btn-primary:hover
{
  background-color: var(--blue);
  padding: 3px 0px;
  font-size: 13px;
  border-radius: 4px;
  text-transform: capitalize;
  font-weight: 400;
  color: #fff;
}
span.pl-percent {
  font-size: 15px;
}
.pl-title {
  font-size: 18px;
  color: #c8c8c8;
  font-weight: 500;
}
.pl-value {
  font-size: 18px;
  font-weight: 500;
  margin-right: 8px;
}
.holding-detailsval {
  justify-content: space-between;
}


.holding-start .holding-stats-main {
  /* background-color: var(--secondary-bg) !important; */
  background-color: #50505059 !important;
  padding: 12px;
}
.headerbox h6 {
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.1px;
}
.holding-subtitle {
  font-size: 12px !important;
  font-weight: 600 !important;
}
.flash-message svg {
  color: #fff;
}
@media(max-width:767px)
{
  .notification span.MuiBadge-badge {
    font-size: 10px;
    max-height: 15px;
    max-width: 15px;
    min-width: 15px;
    text-align: center;
    line-height: 15px;
    padding: 0;
}

  .holding-wrap .tabs {
    position: fixed;
    right: 0;
    z-index: 9;
    height: 44px;
    background-color: black;
    left: 0;
    margin: 0 auto;
    padding: 0px 12px;
    top: auto;
}
body .holding-start.holding-parent {
  margin-top: 58px;
}
.holding-start.holding-parent {
  margin-bottom: 11px;
}

/* .trade-window .tab-btns {
  padding-top: 45px;
} */
}



/* holding page */
.loader-image {
  display: flex;
  justify-content: center;
  align-items: center;
 
}

.loader {
  
  border: 16px solid #D4D4D4;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 150px;
  background-color: transparent;
  height: 150px;
    position: absolute;
    top: 200px;
    bottom: 250px;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 99999999;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


/* Closed tab */

span.t-type {
  background-color: #ff000033;
  text-align: center;
  font-size: 9px;
  border-radius: 3px;
  color: var(--red);
  padding: 3px 5px;
  line-height: 14px;
  min-width: 75px;
  text-transform: capitalize;
}
span.flash-message {
  background-color: #f19a0069;
  color: #FF9800;
  font-size: 9px;
  text-align: center;
  border-radius: 3px;
  padding: 3px 5px;
  line-height: 14px;
  text-transform: capitalize;
}

.flast-n-tags {
  display: flex;
  align-items: baseline;
}

span.flash-message {
  /* font-weight: 600; */
  margin-left: 4px;
}

/* Closed tab */



/* Dashboard Page */
.dashboard-stats {
  padding-top: 0 !important;
}

span.total-pl {
  font-size: 16px;
  margin-right: 6px;
}
.dashboard-money-img img {
  width: 40px;
}
.dashboard-1, .dashboard-2 {
  width: 50%;
}
body .top-holding svg {
  position: relative;
  top: -1px;
  height: 19px;
  width: 19px;
}
.holding-title1 {
  font-weight: 600;
}

.dashboard-1 p.holding-title1-value {
  font-size: 16px;
}

.dashboard-overview {
  width: 100%;
  justify-content: space-between;
}
.taxes-stats .holding-info-section:last-child {
  margin-bottom: 0;
}

.taxes-stats {
  border: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}




.taxes-stats .holding-info-section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.taxes-stats .holding-info-section .holdig-val {
  font-size: 14px;
}

.pl-stats .holding-label {
  font-size: 12px;
  font-weight: 500;
}

.pl-stats .holdig-val {
  font-size: 16px;
}

.pl-stats {
  margin: 16px 0px;
  text-align: left;
}


.dashboard-sec svg {
  font-size: 21px;
  margin-right: 5px;
  position: relative;
  top: -2px;
}

.dashboard-sec {
  color: #fff;
  font-size: 15px;
  display: flex;
  align-items: center;
  font-weight: 500;
  margin-bottom: 2px;
  text-decoration: underline;
  cursor: pointer;
}

.dashboard-sec-holding {
color:#0094ff;

}
.dashboard-sec span.number {
  font-size: 13px;
  margin-left: 4px;
  font-weight: 500;
}

p.holding-title1-value big {
  font-size: 25px;
  font-weight: 200;
  margin-right: 7px;
}

.top-holding svg {
  position: relative;
  top: -4px;
}

.dashboard-label {
  display: grid;
  text-align: left;
  margin-bottom: 7px;
}
.dashboard-label:last-child {
  margin-bottom: 0;
}

.total-stats {
  border-left: 1px solid #2c2c2c;
  padding-left: 15px;
}

.dashboard-label .holdig-val {
  font-size: 16px;
}
.text-align-left {
  text-align: left;
}
.text-align-center {
  text-align: center;
}
/* Dashboard Page */

.tax-tooltip span.name-header svg {
  font-size: 20px;
}
.tax-tooltip .closed-box {
  margin: 0;
}
.tax-tooltip span.name-header {
  position: absolute;
  top: 3px;
}
p.closed-value.date-text {
  cursor: pointer;
}
.tax-tooltip {
  background-color: rgb(18 18 18);
  padding: 12px;
  border-radius: 6px;
  margin-bottom: 0.5rem!important;
}
.tax-tooltip span.name-header svg {
  font-size: 14px;
  font-weight: 800;
  stroke-width: 3px;
  cursor: pointer;
}
.closed-holding {
  position: relative;
}



/* Execute page */

@media(max-width:767px)
{
  .tax-tooltip .closed-box {
    margin: 0;
    align-items: center;
    min-height: 140.38px;
}
body .trade-window .holding-main .card-body .card-text {
  text-align: center;
}
body .tax-tooltip {
  margin-bottom: 12px;

  margin-top: 15px;
}

span.cancelled-btn {
  font-size: 12px;
  border: 0;
  background-color: var(--red);
  color: #fff;
  padding: 3px 10px;
  border-radius: 3px;
  font-weight: 600;
  display: block;
  position: absolute;
  top: 18px;
  right: 10px;
}
.tags-badges {
  margin-top: 4px;
  margin-bottom: 8px;
}
.custom_panding_heading
{
  padding-top: 0;
}
h5.card-title.assest-name {
  margin-top: 2px;
  margin-bottom: 4px;
}

.open-order-title {
  width: 78%;
}
button.cancel-btn {
  font-size: 12px;
  border: 0;
  background-color: #ffffff;
  color: #000;
  padding: 3px 10px;
  border-radius: 3px;
  font-weight: 600;
  position: absolute;
  top: 18px;
  right: 10px;
}
.executed-stock span.flash-message {
  display: block;
  min-width: 69px;
  height: 20px;
  line-height: 20px;
  padding: 0;
}
.tax-tooltip {
  color: #fff;
}
p.closed-value.date-text {
  font-size: 13px;
  margin-bottom: 0;
}
.closed-top p {
  margin-bottom: 5px;
}
}


/* Login page */
.login-part span {

  font-size: 13px;
}

.trading-img img {
  width: 100%;
}

span.sent-otp-msg {
  font-size: 13px;
  display: block;
  margin-bottom: 10px;
}

span.sent-otp svg {
  font-size: 17px;
  position: relative;
  top: -1px;
}

.resent-row span.sent-otp {
  font-size: 14px;
  color: var(--green);
}

.trading-content {
  color: #fff;
}

.forms .error-msg {
  margin-bottom: 0;
  margin-top: 15px;
}

span.error-msg, .error-msg {
  color: var(--red);
  font-size: 14px;
  display: block;
  margin-bottom: 10px;
}
button.main-btn, button.reg-btn:hover {
  padding: 9px 30px;
  font-family: 'Roboto';
  background-color: var(--blue);
}

button.reg-btn {
  display: block;
  width: 100%;
  margin-top: 15px;
  text-transform: capitalize;
}
.otp-text {
  font-size: 13px;
  margin-bottom: 10px;
}
.confiramtion span a {
  color: var(--blue);
  text-decoration: none;
}

.confiramtion span a:hover {
  text-decoration: underline;
  cursor: pointer;
}
.confiramtion {
  display: flex !important;
  align-items: baseline;
}

.confiramtion span {
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  display: inline-block;
}
.confiramtion input {
  width: auto;
  height: auto;
  margin-right: 9px;
  position: relative;
  top: 4px;
}


.continue-btn {
  text-transform: capitalize;
}

a.resend-btn, a.resend-btn:hover {
  margin-top: 0px;
  padding: 9px 8px;
  font-family: 'Roboto';
  display: block;
  color: #000;
  text-decoration: none;
  background-color: #fff;
  border-radius: 3px;
  width: 100%;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  border-color: #fff;
}
/* .resending {
  display: block;
  background-color: whitesmoke;
  text-align: center;
  font-size: 13px;
  text-transform: capitalize;
  padding: 5px;
  width: 100%;
  margin-bottom: 12px;
  border-radius: 4px;
  color: #333;
} */
.otp-btn-wrap
{
justify-content: center;
}
.otp-btn-wrap button.main-btn {
  width: 100%;
}

.login-back-btn {
  position: absolute;
  top: 21px;
  background: none;
  color: #fff;
  border: 0;
  padding: 0;
}
button.back-btn svg {
  font-size: 20px;
}

@media(min-width:768px)
{

  .order-open + .search-box {
    max-width: 501px;
}
body .trade-window .card-body .short_cell-btn, .trade-window .card-body .executed_shoret-cell
{
  max-width: 70px;
  min-width: 70px;
}

body .card-title.holding-title.holding-percent.qty-side svg {
  width: 20px !important;
  height: 20px !important;
  top: 0px !important;
}

body .qty-p {
  max-width: 90px;
  margin-right: auto;
}

body .card-title.holding-title.holding-percent.qty-side {
  position: absolute;
  right: 3px;
  bottom: 20px;
  font-size: 15px;
}
body h6.closed-value {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
}
body .trade-window .holding-main .card-body .card-text div .avgPrice {
  font-size: 12px;
  margin-bottom: 2px;
  color: #b6b6b6;
}
body .avgPrice h6 {
  padding-top: 3px;
}
body h6.closed-value.date-text {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 14px;
}

  .desktop-landing {
    width: 70%;
  }
  .forms {
    width: 25%;
  }
  
.desktop-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
}


/* Login page */




/* Settings */
body .more-menu {
  padding: 9px;
  font-family: Roboto;
  font-weight: 500;
  display: flex;
  grid-column-gap: 9px;
  column-gap: 9px;
  align-items: center;
  background-color: white;
  font-size: 15px;
}

.notify-box .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
  max-width: 200px;
  width: 100%;
}

.notify-box .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded h3 {
  font-size: 16px;
}

.notification-wrap {
  font-size: 13px;
}
body .more-menu svg {
  font-size: 18px;
}

.more-btn svg {
  padding: 7px;
  background-color: #262424;
  width: 36px;
  height: 36px;
  line-height: normal;
  border-radius: 100%;
  color: #d9c7c7;
}

span.password-v svg {
  font-size: 19px;
  color: #fff;
  cursor: pointer;
}
button.changed-pass {
  width: 100%;
  margin-top: 18px;
  text-transform: capitalize;
}
span.password-v {
  position: absolute;
  top: 54%;
  right: 0;
  right: 8px;
}

.password-field {
  position: relative;
}



.phone-field input {
  padding-left: 40px !important;
}

.phone-field .phone-f {
  position: absolute;
  top: 54%;
  left: 10px;
  font-size: 14px;
  font-weight: 600;
}
.phone-field {
  position: relative;
}

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #2b2e32  inset !important;
  -webkit-text-fill-color: white !important;
  border-color: #767779;
}

.resent-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
button.login-back-btn svg {
  font-size: 17px;
  position: relative;
  top: -1px;
}
.resent-row span {
  color: var(--blue);
  font-size: 13px;
  text-decoration: none;
  cursor: pointer;
}

.register-sec span.password-v {
  position: absolute;
  top: 17%;
  right: 0;
  right: 8px;
}

.register-sec .phone-field .phone-f {
  top: 22%;
}


body .profit {
  color: var(--green) !important;
}

body .loss {
  color: var(--red) !important;
}

.text-align-right {
  text-align: right;
}

.flash-message.cancelled {
  font-size: 12px;
  border: 0;
  background-color: var(--red);
  color: #fff;
  padding: 3px 10px;
  margin-top: 9px;
  border-radius: 3px;
  font-weight: 500;
  display: block;
  max-width: 89px;
  text-align: center;
}

.login-form .forget-password a, .login-form .forget-password a:hover
{
  color: var(--blue);
}


@media(min-width:1500px)
{

  .login-form label {
    font-size: 16px;
  }

  
  .forms {
    width: 18%;
}
.dashboard-ftx-wrap>* {
  width: 25%;
  transition: 0.5s;

}
body .holding-wrap.all-hold .search-box {
  max-width: 25%;
}
.holding-wrap .holding-start.holding-parent .holding-stats-main
{
  width: 25%;

}

#tradeView {
  max-width: 35%;
}

}




.quantity-box {
  background-color: #21212178;
  padding: 10px;
  border-radius: 4px;
  margin-top: 17px;
}



h6.card-subtitle.text-muted.holding-subtitle, h5.card-subtitle.text-muted.holding-subtitle {
  color: #fff !important;
}

h6.card-title.executed-title, h5.card-title.assest-name, h6.card-title.holding-title {
  color: #868686;
}

@media(min-width:768px) and (max-width:840px)
{
  body .card-title.holding-title.holding-percent.qty-side svg {
    width: 15px !important;
    height: 18px !important;
}

body .card-title.holding-title.holding-percent.qty-side {
  position: unset;
  margin-left: auto !important;
  font-size: 13px;
  transition: .5 ease;
}
body .holding-wrap .holding-start.holding-parent .holding-stats-main {
  width: 60%;
  transition: .5 ease;

}

body .holding-wrap.all-hold .search-box {
  max-width: 60%;
  transition: .5 ease;

}
body .trade-window .card-body .executed-buy-btn, body span.flash-message, body .mob_set.lp-type .short_cell-btn, body .mob_set.lp-type .executed_shoret-cell, body .trade-window .card-body .pending-limit_btnn {
  min-width: 54px;
  max-width: 54px;
  transition: .5 ease;
}
body .closed-holding .card-text.d-flex.closed-box {
  width: 73%;
}
}

@media(min-width:768px) and (max-width:1023px)
{
  .trading-content {
    color: #fff;
    width: 90%;
    margin: 0 auto;
    max-width: 90% !important;
}
body .desktop-wrap {
  height: 100vh;
  padding: 20px;
}

body .forms .login-part, body .forms .registration {
  max-height: 100%;
  min-height: 200px !important;
  padding: 21px !important;
  position: relative;
}

body .forms .registration .login-back-btn {
  position: unset;
  padding-bottom: 20px;
}
body .trading-img {
    text-align: center;
}
body .trading-img img {
  width: 100%;
  max-width: 300px;
  transition: 0.5s;

}
body .forms {
  width: 41%;
  transition: 0.5s;

}
  .holding-wrap .holding-start.holding-parent .holding-stats-main {
    width: 45%;
    transition: 0.5s;

}
body .trade-window .card-body .executed-buy-btn, body span.flash-message, body .mob_set.lp-type .short_cell-btn, body .mob_set.lp-type .executed_shoret-cell, body .trade-window .card-body .pending-limit_btnn {
  min-width: 54px;
  max-width: 54px;
  transition: .5 ease;
}

body .closed-holding .card-text.d-flex.closed-box {
  width: 73%;
}
 .holding-wrap.all-hold .search-box {
  max-width: 45%;
  transition: 0.5s;

}



  #tradeView {
    max-width: 58%;
}
.holding-main .holding-stock {
  width: 54%;
}
.trade-window .holding-all-btns .holding-btns .sellandbuybtn {
  width: 32%;
}
body .flash-message.cancelled {
  position: absolute;
  bottom: auto;
  font-size: 10px;
  left: 13px;
  top: auto;
  bottom: 4px;
  max-width: 70px;
  font-weight: 100;
}
body button.cancel-btn {
  position: absolute;
  bottom: auto;
  font-size: 10px;
  left: 13px;
  top: auto;
  bottom: 4px;
}
body .tabs-box .card.mb-2.closed-box .custom_panding_heading {
  padding-bottom: 17px;
}

body .card-title.holding-title.holding-percent.qty-side svg {
  width: 15px !important;
  height: 18px !important;
}

body .card-title.holding-title.holding-percent.qty-side {
font-size: 13px;
transition: 0.5s;
}
body .card-text.d-flex.pending-details {
  flex-wrap: wrap;
  transition: 0.5s;

}

body .card-text.d-flex.pending-details > *, body .card-text.d-flex.pending-details > *:first-child {
  width: 30%;
  transition: 0.5s;

}
body .pending-details .col-4.d-flex.justify-content-center.align-items-center.flex-column {

  align-items: center !important;
  transition: 0.5s;

}


body .closed-holding .closed-box .col-4 {
  width: 34%;
  flex-direction: column !important;
  align-items: center;
  row-gap: 4px;
  transition: 0.5s;
}
.closed-holding .closed-box .col-4 > * {
  text-align: center !important;
  transition: 0.5s;

}
.dashboard-ftx-wrap>* {
  width: 52%;
  transition: 0.5s;

}

.dashboard-ftx-wrap>*:last-child {
  width: 43%;
  transition: 0.5s;

}

body .dashboard-1 p.holding-title1-value, body span.total-pl, body .holding-start p.holdig-val, body  .dashboard-label .holdig-val {
  font-size: 14px;
  transition: 0.5s;

}

body .dashboard-ftx .holding-start .holding-stats-main big {
  font-size: 16px;
  transition: 0.5s;

}

body .holding-start .row.holding-stats p.holding-title1 {
  margin-bottom: 0;
  font-size: 11px;
  color: #868686;
  transition: 0.5s;

}
body .top-holding svg {
  height: 15px;
  width: 15px;
  transition: 0.5s;

}
body .holding-start p.holding-title1-value, body .holding-start p.holding-label, body .taxes-stats .holding-info-section .holdig-val
{
  font-size: 11px;
  transition: 0.5s;

}

body .dashboard-1, body .dashboard-2 {
  width: 48%;
  transition: 0.5s;

}
body .dashboard-money-img img {
  width: 35px;
  transition: 0.5s;
}

}

@media(min-width:1024px) and (max-width:1220px)
{

  body .forms {
    width: 30%;
    transition: 0.5s;

  }

  #tradeView {
    max-width: 45%;
}
.holding-wrap .holding-start.holding-parent .holding-stats-main, .holding-wrap.all-hold .search-box {
  transition: 0.5s;

}
body .pending-details .col-4.d-flex.justify-content-center.align-items-center.flex-column {

  align-items: center !important;
  transition: 0.5s;

}
.holding-wrap.all-hold .search-box {
  max-width: 30%;
  transition: 0.5s;

}
body .card-text.d-flex.pending-details {
  flex-wrap: wrap;
  transition: 0.5s;

}

body .card-text.d-flex.pending-details > *, body .card-text.d-flex.pending-details > *:first-child {
  width: 30%;
  transition: 0.5s;

}

body .trade-window .card-body .executed-buy-btn, body span.flash-message, body .mob_set.lp-type .short_cell-btn, body .mob_set.lp-type .executed_shoret-cell, body .trade-window .card-body .pending-limit_btnn {
  min-width: 54px;
  max-width: 54px;
  transition: .5 ease;
}
body .closed-holding .card-text.d-flex.closed-box {
  width: 73%;
}

.dashboard-ftx-wrap>* {
  width: 45%;
  transition: 0.5s;

}
.dashboard-money-img {
  width: 10%;
  transition: 0.5s;
}
body .dashboard-1, body .dashboard-2 {
  width: 48%;
  transition: 0.5s;

}
body .dashboard-money-img img {
  width: 43px;
  transition: 0.5s;
}

}


@media(min-width:1221px) and (max-width:1365px)
{
  #tradeView{
    max-width: 44%;
}
.holding-wrap.all-hold .search-box {
  max-width: 30%;
}
body .card-text.d-flex.pending-details > * {
  width: auto;
}

.dashboard-ftx-wrap>*:last-child {
  width: 32%;
  transition: 0.5s;

}

.dashboard-ftx-wrap>* {
width: 40%;
transition: 0.5s;
}

}

@media(min-width:1366px) and (max-width:1499px)
{
  .dashboard-ftx-wrap>*:last-child {
    width: 32%;
    transition: 0.5s;

}
.holding-wrap.all-hold .search-box {
  max-width: 30%;
  transition: 0.5s;

}
.dashboard-ftx-wrap>* {
  width: 39%;
  transition: 0.5s;
}
}

/* @media(min-width:1366px)
{
  .holding-wrap.all-hold .search-box {
    max-width: 30%;
  }
} */


@media(max-width:767px)
{
  .container, .container-sm {
    max-width: 100%;
}
.login-form, .register-area {
  max-width: 306px;
  margin: 0 auto;
}
}



/*
.MuiPagination-root{
  background-color: grey;
}

.MuiPagination-root{
  background-color: grey;
}*/

.MuiPaginationItem-text{
color: white !important;
}

.pagination-message{
  color: white;
}

.place-order-success .MuiPaper-root{
  
  background-color:rgb(36, 38, 46) !important;
  }

  .confirmBtnStyle {
   width: 100%;
   margin-bottom: 10px;
   margin-right: 0px;
   background-color: var(--blue);
   padding: 3px 0px;
   font-size: 13px;
   border-radius: 4px;
   text-transform: capitalize;
   font-weight: 400;
   color: #fff;
   
  
  }
  
  .search-main {
  margin-top: 15px;
  } 

    
  .search-main-orders {
    margin-top: 50px;
    } 

  .assets-listing{
    margin-top: 15px;
  }

  .options-details-back-button {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 15px;
    color : #fff;
    } 


    .assets-listing .MuiTab-root{
      padding: 3px;
    }

    .expiry-date{
      padding: 3px;
      color : #fff;
    }

    .pagination-list{
      padding-top: 5px;
      padding-bottom: 5px;
     
    }

    .order-search-close{
      margin-left: 200px;
    }
