@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');



/* Desktop large css */

@media(min-width:768px)
{
    .dark-theme.tournament-page .tournament-body {
    background-color: #1d2027;
    padding: 15px;
    width: calc( 100% - 12px);
    margin-left: auto;
}
.dark-theme.tournament-page {
    background-color: #000 !important;
    background: none;

}
}
/* Desktop large css */



.dark-theme.tournament-page {
    background-color: #2a2b30;
    color:#fff;
    font-family: 'Poppins', sans-serif;

}
.header-container {
    padding-bottom: 10px;
    z-index: 99;
}
.tournament-page {
    font-family: 'Poppins', sans-serif;

}
.user-account span {
    font-size: 14px;
    font-weight: 600;
}
.user-account {
    text-align: right;
}

.tournament-header img {
    width: 100%;
}
.tournament-body .MuiPaper-root.MuiPaper-elevation {
    position: relative;
    margin-bottom: 20px;
}


.MuiPaper-elevation .MuiBox-root {
    padding: 0px 0px;
}
span.MuiLinearProgress-bar
{
    background-color: #ff5b0a;
}

.tournament-header h3
{
    margin-bottom: 0px;
}
.tournament-header
{
    padding: 15px;
}
.tournament-header {
    border-bottom: 2px solid #a3a3a352;
}
.tournament-body
{
background-color: #2a2b30;
padding: 15px;
}

.tournament-dark .MuiDrawer-paperAnchorLeft
{
    background-color: #2a2b30;  
    color: #fff;
    font-family: 'Poppins', sans-serif;

}

.tournament-body .MuiPaper-root.MuiPaper-elevation {
    position: relative;
}

.tournament-body .MuiCardContent-root {
    position: absolute;
    top: 0;
    font-family: Poppins;
}

.tournament-body .MuiTypography-root.MuiTypography-h3 {
    font-weight: 700;
    font-size: 36px;
}
.tournament-body .tournament-actions + .MuiGrid-root a {
    background-color: #ffdd3c;
    color: #000;
    font-weight: 600;
    font-size: 14px;
    padding: 9px 18px;
    border-radius: 100px;
    text-decoration: none;
}
.tournament-body .MuiTypography-root.MuiTypography-body1 {
    background-color: #202020;
    color: #fff;
    padding: 7px 19px;
    border-radius: 8px;
    font-family: Poppins;
}

.tournament-body .tournament-actions h1 {
    font-size: 27px;
    font-weight: 800;
    color: #ffdd3c;
}

.tournament-body .MuiCardMedia-root:after {
    background-color: #00000091;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 180px;
}
.tournament-heading p span svg {
    font-size: 13px;
}
.dark-theme .MuiCardActions-root {
    background-color: black;
    color: #fff;
    padding: 15px 10px;
}

.dark-theme  .tournament-body .MuiTypography-root.MuiTypography-h3
{
    color: #ffffff;
}


.tournament-heading svg {
    font-size: 31px;
}

.tournament-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    border-bottom: 1px solid #58585873;
}
.tournament-heading p {
    margin-bottom: 0;
}


.single-tournament .MuiCardMedia-root {
    position: relative;
    font-family: Poppins;
}

.single-tournament .MuiCardMedia-root:After {
    content: '';
    background-image: linear-gradient(to top, rgb(0 0 0 / 74%), rgb(0 0 0 / 25%));
    position: absolute;
    height: 100%;
    right: 0;
    bottom: 0;
    left: 0;
}

.single-tournament  .MuiTypography-h3 {
    position: absolute;
    bottom: 123px;
    color: #fff;
    font-weight: 500;
    font-size: 38px;
}
.single-tournament .tournament-timer {
    position: absolute;
    top: -340px;
    background-color: rgba(0,0,0,.8392156862745098);
    color: #fff;
    padding: 6px 20px;
    border-radius: 8px;
    margin: 0 auto;
    display: block;
    margin: 0 auto;
    left: 0;
    right: 0;
    width: 76%;
    text-align: center;
    font-family: Poppins;
}

.single-tournament .MuiTypography-h3 span {
    font-size: 17px;
    display: block;
    width: 99%;
    color: #c6c6c6;
    padding-top: 10px;
    font-weight: 400;
}


.single-tournament .MuiCardActions-root a {
    background-color: #ffdd3c;
    color: #000;
    font-weight: 600;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 100px;
    text-decoration: none;
    width: 92%;
    display: block;
    position: absolute;
    bottom: 40px;
    margin: 0 auto;
    text-align: center;
    left: 0;
    right: 0;
}

.tournament-terms .MuiPaper-root, .tournament-terms .MuiAccordionSummary-root, .tournament-terms .MuiTypography-root {
    font-family: 'Poppins', sans-serif;
}

.single-tournament  .MuiCardActions-root {
    padding: 0;
}

.single-tournament  .MuiCardContent-root {
    padding-bottom: 0;
    padding-top: 0;
    position: relative;
}

/* .tournament-dark .MuiDrawer-paperAnchorLeft {
    margin-top: 84px;
} */

.tournament-dark .MuiBackdrop-root {
    background: none;
}

.terms-row {
    font-size: 15px;
    text-align: center;
    font-weight: 500;
}

.terms-row h3 {
    font-size: 20px;
    font-weight: 700;
}
.terms-row {
    padding: 15px 0px;
}
.terms-row svg {
    font-size: 30px;
    margin-bottom: 10px;
}

.single-tournament .MuiAccordionDetails-root {
    color: #fff;
    background-color: #2a2b30;

}

.single-tournament .MuiTableCell-body, .single-tournament  .MuiTableCell-head {
    border: 0;
    font-family: Poppins;
}

.single-tournament table .MuiTableCell-head {
    background-color: #00000045;
    padding: 11px 15px;
}
.single-tournament {
    overflow: scroll;
    height: 100%;
    padding-bottom: 20px;
}

.single-tournament   .Mui-expanded
{
    margin: 0 !important;
}


.single-tournament .MuiAccordionSummary-expandIconWrapper
{
    top: inherit;
}
.tournament-page {
    overflow: auto;
    height: 100vh;
}

@media(min-width:768px)
{
    .tournament-body .MuiCard-root {
        flex-basis: auto;
        margin: 15px;
        flex: 1 1 auto;
        max-width: 24%;
        width: auto;
    }
    .tournament-header img {
        max-height: 65px;
        width: auto;
    }
    .tournament-body {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: left;
    }
}




@media(max-width:767px)
{
    .single-tournament .starting-balance {
        margin-top: 40px;
    }

    .tournament-header {
        position: absolute;
        height: 80px;
    }
   
}
.tournament-header
{
    display: none !important;
}
.single-tournament .MuiCardActions-root {
    position: relative;
}



.tournament-terms {
    overflow: auto;
    height: auto;
    padding-bottom: 113px;
}


.user-account h3 span svg {
    font-size: 26px;
    padding: 0;
    position: relative;
    top: -4px;
    line-height: 0;
}

.tournament-header {
    z-index: 999;
    background: #25262a;
}
.dark-theme .drawer-heading {
    border-bottom: 1px solid rgba(65,65,65,.3803921568627451);
    color: #fff;
}
.drawer-heading {
    text-align: left;
    padding: 12px;
    font-size: 18px;
}
.drawer-heading h3 {
    font-size: 18px;
    padding-bottom: 0;
    font-family: Poppins;
    margin-bottom: 0;
}
.dark-theme .MuiDrawer-paperAnchorRight {
    background-color: #000;
}
.account-menu .account-li {
    padding: 16px 10px 16px !important;
}
.drawer-heading span {
    position: absolute;
    right: 8px;
    top: 8px;
}
.account-menu {
    padding: 12px;
}
.account-li {
    margin-bottom: 10px;
}
.account-menu .account-li {
    padding: 10px 10px 16px;
}

.dark-theme .account-li {
    color: #fff;
    background-color: rgba(65,65,65,.3803921568627451);
    padding: 7px;
    border: 2px solid rgba(65,65,65,.3803921568627451);
    border-radius: 6px;
    font-family: Poppins;
}

.dark-theme .account-li span.d-block.username {
    font-size: 17px!important;
}
.dark-theme .account-li span {
    color: #acacac;
    font-size: 14px;
}
span.d-block.username {
    color: #f9b42e;
    font-size: 13px;
    display: block;
}
.account-li span {
    display: block;
}   


.account-switch:after {
    background-color: #000;
    content: "";
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1;
}
.account-switch small {
    display: block;
    color: #5d5d5d;
    font-family: 'Poppins';
}
.account-switch p {
    font-size: 20px;
    font-family: 'Poppins';
}
.account-switch {
    position: absolute;
    z-index: 999999;
    margin: 0 auto;
    text-align: center;
    width: 100%;
    display: flex;
    align-items: center;
    height: 80vh;
    flex-direction: column;
    justify-content: center;
    row-gap: 20px;
}
.account-switch .tradenow-btn {
    background-color: #ffeb3b;
    font-family: 'Poppins';
    font-size: 20px;
    box-shadow: none;
    border: 0;
    padding: 7px 20px;
    border-radius: 6px;
    color: #000;
}

.account-switch svg {
    font-size: 95px;
}
.account-switch {
    color: #fff;
}
.active-account {
    position: relative;
}
.active-account:after {
    content: "Selected";
    background-color: #8bc34a;
    padding: 1px 9px;
    font-size: 14px;
    border-radius: 2px;
    position: absolute;
    right: 13px;
    top: 26px;
}

@media(min-width:768px)
{
.MuiDrawer-paperAnchorRight .MuiBox-root {
    width: 100% !important;
}
.MuiDrawer-paperAnchorRight {
    background-color: #000;
    width: 400px;
    clear: both;
}
}

.active-t-titles h6 big {
    padding-left: 7px;
    color: #ffc107;
    font-size: 22px;
}

.active-t-titles h6 {
    font-size: 17px;
}
.active-t-titles {
    padding-left: 15px;
}
.active-tournament .MuiTypography-root {
    font-family: 'Poppins' !important;
}

.active-tournament .active-t-header img {
    width: 100%;
    border-radius: 9px;
    height: 90px;
    object-fit: cover;
    object-position: revert;
}

.active-t-value h6 span {
    background-color: #202020;
    color: #fff;
    padding: 5px 18px;
    border-radius: 6px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
}
.active-t-value {
    text-align: right;
}

.active-tournament {
    background-color: #3e3f45;
    padding: 14px;
    border-radius: 5px;
    margin-bottom: 30px;
    position: relative;
    border-bottom: 5px solid #0202027a;
}

.active-progress {
    position: absolute;
    bottom: -5px;
    left: 0;
    border-bottom-left-radius: 8px;
}

.active-tournament .MuiLinearProgress-root {
    height: 5px;
    border-bottom-left-radius: 8px;
}

.active-tournament span.MuiLinearProgress-bar {
    background-color: #ebebeb;
    border-bottom-left-radius: 8px;
}
.user-details-active {
    align-items: center;
}
.user-details-active img {
    width: 100%;
    width: 40px;
    height: 40px;
    border-radius: 100px;
    object-fit: cover;
    border: 2px solid #515151;
}

.user-details-active {
    border-top: 2px solid #494949;
    padding: 10px 7px;
    border-bottom: 2px solid #494949;
}

.user-details-active h6 {
    font-size: 12px;
    margin-bottom: 0;
}
.user-details-active span {
    font-size: 13px;
}
.user-details-active h3 {
    color: #fdda45;
    font-size: 12px;
    margin-bottom: 0;
}